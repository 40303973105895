import { useQueries } from '@tanstack/react-query'
import { GET } from '../../../fixrateCity/src/utils/networkCallApi'
import { EChartsOption } from '../echarts'
import EChart from '../EChart'
import { PURPLE, SIGNAL_YELLOW, SPRING_GREEN } from '../../../../colors/colors'
import { Box, Typography } from '@mui/material'
import CurrencyOutput from '../../../fixrateCity/src/components/CurrencyOutput/CurrencyOutput'
import { Period } from '../../KPIDashboard'

type IncomeData = {
  WEEK: {
    value: number
    change: number
    budget: number
  }
  MONTH: {
    value: number
    change: number
    budget: number
  }
  YTD: {
    value: number
    budget: number
  }
}

export const IncomeSeriesBarChart = ({ token }: { token: string }) => {
  const period: Period = 'YTD'
  const results = useQueries({
    queries: [
      {
        queryKey: ['income-series' + period, token],
        queryFn: () => GET('/api/stagg/kpi/income-series?range=' + period, token),
      },
      {
        queryKey: ['income', token],
        queryFn: () => GET('/api/stagg/kpi/income', token),
      },
    ],
  })

  let incomeData: IncomeData
  let incomeValue: number = 0
  let incomeChange: number = 0

  let incomeSeries

  let depositsMonthly: { [key: string]: number }
  let bankMonthly: { [key: string]: number }
  let fundMonthly: { [key: string]: number }

  let bankData: number[]
  let fundData: number[]
  let depositsData: number[]
  let budgetData: number[]
  let months: string[]

  const periodMapping: { [key in Period]: string } = {
    '1W': 'WEEK',
    '1M': 'MONTH',
    YTD: 'YTD',
  }
  
  const periodString = periodMapping[period]

  if (!results[0].isPending && results[0].isSuccess && !results[1].isPending && results[1].isSuccess) {
    incomeSeries = results[0]
    incomeData = results[1].data
    const dataToMap = results[0].data[0].series
    const series = dataToMap.reduce(
      (result, data) => ({
        ...result,
        [data.name]: data.values,
      }),
      {}
    )

    depositsMonthly = series.deposits.reduce((acc, [date, value]) => {
  const month = date.slice(0, 7); // Extract the "YYYY-MM" part of the date
  if (!acc[month]) {
    acc[month] = 0;
  }
  acc[month] += value;
  return acc;
    }, {});

    bankMonthly = series.bank.reduce((acc, [date, value]) => {
      const month = date.slice(0, 7); // Extract the "YYYY-MM" part of the date
      if (!acc[month]) {
        acc[month] = 0;
      }
      acc[month] += value;
      return acc;
    }, {});

    fundMonthly = series.fund.reduce((acc, [date, value]) => {
      const month = date.slice(0, 7); // Extract the "YYYY-MM" part of the date
      if (!acc[month]) {
        acc[month] = 0;
      }
      acc[month] += value;
      return acc;
    }
      , {});
    
    const budgetMonthly = series.budget.reduce((acc, [date, value]) => {
  const month = date.slice(0, 7); // Extract the "YYYY-MM" part of the date
  const day = date.slice(8, 10); // Extract the "DD" part of the date

  if (!acc[month]) {
    acc[month] = { valueFor15th: null, latestValue: null };
  }

  if (day === '15') {
    acc[month].valueFor15th = value;
  }

  if (!acc[month].latestValue || date > acc[month].latestValue.date) {
    acc[month].latestValue = { date, value };
  }

  return acc;
}, {});

const finalBudgetMonthly = Object.keys(budgetMonthly).reduce((acc, month) => {
  const { valueFor15th, latestValue } = budgetMonthly[month];
  acc[month] = (valueFor15th !== null ? valueFor15th : latestValue.value) * 30;
  return acc;
}, {});
        
const combinedMonthly = {};

  const allMonths = new Set([
    ...Object.keys(bankMonthly),
    ...Object.keys(fundMonthly),
    ...Object.keys(depositsMonthly),
    ...Object.keys(finalBudgetMonthly),
  ]);

  allMonths.forEach(month => {
    combinedMonthly[month] = {
      bank: bankMonthly[month] || 0,
      fund: fundMonthly[month] || 0,
      deposits: depositsMonthly[month] || 0,
      budget: finalBudgetMonthly[month] || 0,
    };
  });

  months = Object.keys(combinedMonthly).sort();
  bankData = months.map(month => +bankMonthly[month] ? +bankMonthly[month].toFixed(0):0);
  fundData = months.map(month => +fundMonthly[month] ? +fundMonthly[month].toFixed(0):0);
    depositsData = months.map(month => +depositsMonthly[month] ? +depositsMonthly[month].toFixed(0):0);
    budgetData = months.map(month => +finalBudgetMonthly[month] ? +finalBudgetMonthly[month].toFixed(0) : 0);

    incomeValue = incomeData[periodString].value.toFixed(0)
    incomeChange = incomeData[periodString].change ? incomeData[periodString].change.toFixed(0) : 0
  }

  const incomeSeriesOptions: EChartsOption = {
    tooltip: {},
    legend: {
      data: ['Bank', 'Innskudd', 'Fond', 'Budsjett'],
      right: '5%',
      top: 0,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: 'category',
      data: months,
    },
    yAxis: [
      {
        type: 'value',

      }
    ],
    grid: {
            bottom: '20rem',
            left: '80rem',
            right: '20rem',
        },

    series: [
      {
        name: 'Bank',
        type: 'bar',
        stack: 'total',
        data: bankData,
        color: PURPLE[50],
      },
      {
        name: 'Fond',
        type: 'bar',
        stack: 'total',
        data: fundData,
        color: SPRING_GREEN[500],
      },
      {
        name: 'Innskudd',
        type: 'bar',
        stack: 'total',
        data: depositsData,
        color: PURPLE[300],
      },
      {
        name: 'Budsjett',
        type: 'line',
        smooth: true,
        lineStyle: {
          width: 2,
          color: SIGNAL_YELLOW[600],
        },
        showSymbol: true,
        data: budgetData,
        color: SIGNAL_YELLOW[600],
      },
    ],
  };

  const incomeTrend = (value: number) => {
    if (value === 0) return ''
    if (value.toString().length > 6)
      return `+ ${CurrencyOutput.formatMillionWithDecimals(value)}`

    return `${CurrencyOutput.formatDecimal(value)}`
  }

  return (
    <Box style={{ height: '31vh' }}>
      <Typography variant='h2' fontWeight={800} display={'inline-flex'}>
        {incomeValue.toString().length > 6 ? CurrencyOutput.formatMillionWithDecimals(incomeValue) : CurrencyOutput.formatDecimal(incomeValue)}
        <Box sx={{ display: 'inline-flex' }}>
          <Typography sx={{ display: 'flex', pl: '1rem', color: SPRING_GREEN[500], alignItems: 'center', height: '100%' }} variant='h5'>
            {incomeTrend(incomeChange)}
          </Typography>
        </Box>
      </Typography>
      <EChart loading={incomeSeries?.isPending} options={incomeSeriesOptions} error={incomeSeries?.error} style={{ height: '28vh' }} />
    </Box>
  )
}